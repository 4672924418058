@import "./assets/styles/utilities.scss";
@import "./assets/styles/resets.scss";

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');

html, body {
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
}

body {
  border-radius: 1em;
  color: #000000;
  font-size: 18px;
  background-color: #FFFFFF;

  // CUSTOM!
  @media screen and (max-width: 1240px){
    font-size: 16px;
  } // End CUSTOM!

  // Secondary Mobile -  BIG Phone & Below
  @media screen and (max-width: 840px){
    font-size: 15px;
  } // End Big Phone & Below
} // End body

main {
  overflow: hidden;
}

footer,
section {
  padding: 6em 0;

  // Secondary Mobile - Big Phone & Below
  @media screen and (max-width: 840px){
    padding: 4em 0;
  } // End Big Phone & Below
}

a {
  > img {
    @include animate;
  }
}

nav {
  display: flex;
  gap: 1em;
  margin-bottom: 2em;

  // Secondary Mobile - Big Phone & Below
  @media screen and (max-width: 840px){
    flex-wrap: wrap;
    gap: 2em;
    justify-content: center;
  } // End Big Phone & Below
}

p {
  font-size: 1em;
  line-height: 150%;
  margin-bottom: 1rem;

  &.medium {
    font-size: 1.2em;
  }

  &.large {
    font-size: 1.6em;
  }
}

strong {
  font-weight: 800;
}

em {
  font-style: italic;
}

strong {
  em {
    font-weight: 800; /* ExtraBold */
    font-style: italic;
  }
}

h1 {
  font-size: 1.8em;
  line-height: 1.3;
  margin-bottom: 1.8rem;

  // Secondary Mobile - Big Phone & Below
  @media screen and (max-width: 840px){
    font-size: 1.6em;
    margin-bottom: 1.6rem;
  } // End Big Phone & Below
}

// Wrap
.wrap {
  @include better-box(flex);
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 58em;
  width: 90%;

  &.wide {
    max-width: 92em;
  }

  &.mobile-full {
    // Secondary Mobile - Big Phone & Below
    @media screen and (max-width: 840px){
      max-width: 100%;
      width: 100%;
    } // End Big Phone & Below
  }
}

.content-frame {
  @include full-box;
  background-color: #FFFFFF;
  border-radius: 2em;
  padding: 3em 0;
  position: relative;
  z-index: 1;
}

.gradient-bg {
  background: linear-gradient(rgba(255,255,255, 1), rgba(255,255,255, 0));
  position: relative;
  z-index: 2;
}

// Orbs
.blurred-orb {
  animation: pulse 12s infinite ease-in-out;
  border-radius: 50%;
  filter: blur(6em);
  pointer-events: none;
  position: fixed;
  transform: translate(-50%, -50%);

  &.brand {
    background: radial-gradient(circle, rgba(255, 210, 52, 0.8), rgba(255, 210, 52, 0));
    height: 64vh;
    left: -18vh;
    top: 80%;
    width: 64vh;

    // Secondary Mobile -  BIG Phone & Below
    @media screen and (max-width: 840px){
      left: -20em;
    } // End Big Phone & Below
  }

  &.purple {
    animation-delay: 6s;
    animation-duration: 22s;
    background: radial-gradient(circle, rgba(199, 85, 240, 0.8), rgba(199, 85, 240, 0));
    height: 84vh;
    right: -102vh;
    top: 0%;
    width: 84vh;

    // Secondary Mobile -  BIG Phone & Below
    @media screen and (max-width: 840px){
      right: -136vh;
    } // End Big Phone & Below
  }
}

@keyframes pulse {
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.4);
    opacity: 0.8;
  }
}

.mobile-hide {
  // Secondary Mobile -  BIG Phone & Below
  @media screen and (max-width: 840px){
    display: none;
  } // End Big Phone & Below
}

.underline {
  text-decoration: underline;
}

// Layout Specifcs
.welcome-banner + .hero {
  .app-hero-content {
    position: relative;
    top: -3em;

    // Secondary Mobile -  BIG Phone & Below
    @media screen and (max-width: 840px){
      top: -1em;
    } // End Big Phone & Below
  }
}