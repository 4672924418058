*, html, body, ul, ol, li, img, p, a {
  @include no-tap;
  border: 0;
  box-sizing: border-box;
  font-weight: normal;
  line-height: 1;
  list-style-image: none;
  list-style-type: none;
  margin: 0;
  outline: 0;
  padding: 0;
  text-decoration: none;
}

a, a:link, a:visited, a:active, input, button, textarea, select {
  @include animate;
  border-radius: 0;
}

button, textarea, select {
  @include browser-prefix(appearance, none);
}

button::-moz-focus-inner, input[type="reset"]::-moz-focus-inner, input[type="button"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner, select::-moz-focus-inner {
  border: 0;
  margin: 0;
  padding: 0;
  width: 0;
}

a > svg {
  pointer-events: none;
}

svg {
  fill: inherit;
}

// Selections
@mixin selection {
  background: black;
  color: white;
}

::selection { @include selection; }
::-moz-selection { @include selection; }
